#homeVideo {
  height: 450px;
}

@media only screen and (min-width : 480px) {
  #homeVideo {
    height: 200px;
  }
}

@media only screen and (min-width : 768px) {
  #homeVideo {
    height: 250px;
  }
}

@media only screen and (min-width : 992px) {
  #homeVideo {
    height: 300px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  #homeVideo {
    height: 400px;
  }
}