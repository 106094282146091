#nprogress {
  pointer-events: none
}

#nprogress .bar {
  background: #0da893;
  position  : fixed;
  z-index   : 100000;
  top       : 0;
  left      : 0;
  width     : 100%;
  height    : 2px
}

#nprogress .peg {
  display          : block;
  position         : absolute;
  right            : 0;
  width            : 100px;
  height           : 100%;
  box-shadow       : 0 0 10px #0da893, 0 0 5px #0da893;
  opacity          : 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform    : rotate(3deg) translate(0, -4px);
  transform        : rotate(3deg) translate(0, -4px);
}

#nprogress .spinner {
  position        : fixed;
  z-index         : 100000;
  background-color: rgba(0, 0, 0, 0.2);
  display         : none;
  justify-content : center;
  align-items     : center;
  height          : 100%;
  width           : 100%;
  top             : 2px;
  right           : 0px;
}

#nprogress .spinner-icon {
  width            : 50px;
  height           : 50px;
  box-sizing       : border-box;
  border           : 5px solid transparent;
  border-top-color : #0da893;
  border-left-color: #0da893;
  border-radius    : 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation        : nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}